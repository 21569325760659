var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orgLabel-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          { ref: "formPanel", on: { update: _vm.update } },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("div", { staticClass: "topDesc" }, [
            _c("h3", [_vm._v("温馨提示：")]),
            _c("p", [
              _vm._v(
                "标签属性是对用户C端提交组织申请—【其他信息】、管理后台新增组织—【组织信息】进行管理的功能。在该处可以新增/编辑不同标签所需要的字段内容，包括但不限于【图片】、【pdf附件】、【文案描述】等"
              ),
            ]),
          ]),
          _c("div", { staticClass: "group-cell" }, [
            _c("div", { staticClass: "c-left" }, [
              _c("span", [_vm._v("*")]),
              _vm._v("标签名称："),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.$route.query.tagName))]),
          ]),
          _c("div", { staticClass: "group-cell" }, [
            _c("div", { staticClass: "c-left" }, [
              _c("span", [_vm._v("*")]),
              _vm._v("基础属性："),
            ]),
            _c(
              "div",
              _vm._l(_vm.basisAttrlist, function (item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "control-list" },
                    [
                      _c("v-input", {
                        attrs: { label: "属性名", disabled: "" },
                        model: {
                          value: item.attributeName,
                          callback: function ($$v) {
                            _vm.$set(item, "attributeName", $$v)
                          },
                          expression: "item.attributeName",
                        },
                      }),
                      item.inputType === 1
                        ? _c("v-input-number", {
                            attrs: { disabled: "", label: "位数" },
                            model: {
                              value: item.attributeLength,
                              callback: function ($$v) {
                                _vm.$set(item, "attributeLength", $$v)
                              },
                              expression: "item.attributeLength",
                            },
                          })
                        : _c("v-input-number", {
                            attrs: { disabled: "", label: "大小" },
                            model: {
                              value: item.attributeLength,
                              callback: function ($$v) {
                                _vm.$set(item, "attributeLength", $$v)
                              },
                              expression: "item.attributeLength",
                            },
                          }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "group-cell" }, [
            _c("div", { staticClass: "c-left" }, [_vm._v("其他属性：")]),
            _c(
              "div",
              [
                _vm._l(_vm.attrList, function (item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      { staticClass: "control-list" },
                      [
                        _c("v-input", {
                          attrs: { label: "属性名" },
                          model: {
                            value: item.attributeName,
                            callback: function ($$v) {
                              _vm.$set(item, "attributeName", $$v)
                            },
                            expression: "item.attributeName",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "是否必填",
                            options: _vm.requiredOps,
                          },
                          model: {
                            value: item.required,
                            callback: function ($$v) {
                              _vm.$set(item, "required", $$v)
                            },
                            expression: "item.required",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: " 输入类型",
                            options: _vm.inputTypeOps,
                          },
                          model: {
                            value: item.inputType,
                            callback: function ($$v) {
                              _vm.$set(item, "inputType", $$v)
                            },
                            expression: "item.inputType",
                          },
                        }),
                        item.inputType === 1
                          ? _c("v-input-number", {
                              attrs: { label: "位数", min: 0 },
                              model: {
                                value: item.attributeLength,
                                callback: function ($$v) {
                                  _vm.$set(item, "attributeLength", $$v)
                                },
                                expression: "item.attributeLength",
                              },
                            })
                          : _vm._e(),
                        item.inputType === 2
                          ? _c("v-input-number", {
                              attrs: { label: "大小", min: 0, max: 5 },
                              model: {
                                value: item.attributeLength,
                                callback: function ($$v) {
                                  _vm.$set(item, "attributeLength", $$v)
                                },
                                expression: "item.attributeLength",
                              },
                            })
                          : _vm._e(),
                        item.inputType === 3
                          ? _c("v-input-number", {
                              attrs: { label: "大小", min: 0, max: 10 },
                              model: {
                                value: item.attributeLength,
                                callback: function ($$v) {
                                  _vm.$set(item, "attributeLength", $$v)
                                },
                                expression: "item.attributeLength",
                              },
                            })
                          : _vm._e(),
                        item.inputType === 4
                          ? _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: {
                                    label: "选项",
                                    placeholder: "查询选项名称",
                                  },
                                  model: {
                                    value: item.attributeSource,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attributeSource", $$v)
                                    },
                                    expression: "item.attributeSource",
                                  },
                                },
                                "v-select2",
                                _vm.searchParams,
                                false
                              )
                            )
                          : _vm._e(),
                        _c("v-button", {
                          staticClass: "drop",
                          attrs: { text: "删除", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.removeAttr(index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                _c("v-button", {
                  attrs: { text: "添加" },
                  on: { click: _vm.addAttr },
                }),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "footerSlot" }, slot: "footerSlot" },
            [
              _c("v-button", {
                attrs: { type: "success", width: 168, text: "保存" },
                on: { click: _vm.saveTagAttr },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }