<template>
  <div class="orgLabel-container">
    <!-- <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div> -->
    <form-panel
       ref="formPanel"
      v-bind="submitConfig"
      @update="update"
    >
      <div class="topDesc">
        <h3>温馨提示：</h3>
        <p>标签属性是对用户C端提交组织申请—【其他信息】、管理后台新增组织—【组织信息】进行管理的功能。在该处可以新增/编辑不同标签所需要的字段内容，包括但不限于【图片】、【pdf附件】、【文案描述】等</p>
      </div>
      <div class="group-cell">
        <div class="c-left"><span>*</span>标签名称：</div>
        <div>{{$route.query.tagName}}</div>
      </div>
      <div class="group-cell">
        <div class="c-left"><span>*</span>基础属性：</div>
        <div>
          <div v-for="(item, index) in basisAttrlist" :key="index">
            <div class="control-list">
              <v-input label="属性名" v-model="item.attributeName" disabled></v-input>
              <v-input-number disabled v-if="item.inputType === 1" label="位数" v-model="item.attributeLength"></v-input-number>
              <v-input-number disabled v-else label="大小" v-model="item.attributeLength"></v-input-number>
            </div>
          </div>
        </div>
      </div>
      <div class="group-cell">
        <div class="c-left">其他属性：</div>
        <div>
          <div v-for="(item, index) in attrList" :key="index">
            <div class="control-list">
              <v-input label="属性名" v-model="item.attributeName"></v-input>
              <v-select label="是否必填" v-model="item.required" :options="requiredOps"></v-select>
              <v-select label=" 输入类型" v-model="item.inputType" :options="inputTypeOps"></v-select>
              <v-input-number v-if="item.inputType === 1" label="位数" v-model="item.attributeLength" :min='0'></v-input-number>
              <v-input-number v-if="item.inputType === 2" label="大小" v-model="item.attributeLength" :min='0' :max="5"></v-input-number>
              <v-input-number v-if="item.inputType === 3" label="大小" v-model="item.attributeLength" :min='0' :max="10"></v-input-number>
              <v-select2 v-if="item.inputType === 4" label="选项" placeholder="查询选项名称" v-model="item.attributeSource" v-bind="searchParams" />
              <v-button class="drop" text="删除" type="danger" @click="removeAttr(index)"></v-button>
            </div>
          </div>
          <v-button text="添加" @click="addAttr"></v-button>
        </div>
      </div>
      <div slot="footerSlot">
        <v-button type="success" :width="168" text="保存" @click="saveTagAttr"></v-button>
      </div>
    </form-panel>
  </div>
</template>
<script>
import {
  getTagAttributeListUrl,
  updategTagAttributeListUrl,
  getTagAttrsURL
} from './api'
export default {
  data () {
    return {
      formId: this.$route.query.id,
      basisAttrlist: [],
      attrList: [],
      requiredOps: [
        {
          text: '是',
          value: 1
        },
        {
          text: '否',
          value: 0
        }
      ],
      inputTypeOps: [
        {
          text: '文本输入',
          value: 1
        },
        {
          text: '图片上传',
          value: 2
        },
        {
          text: '附件上传',
          value: 3
        },
        {
          text: '下拉选择',
          value: 4
        }
      ],
      submitConfig: {
        queryUrl: `${getTagAttributeListUrl}${this.$route.query.id}`
      },
      searchParams: {
        searchUrl: getTagAttrsURL,
        subjoin: {
          purpose: 'ORG_TAG'
        }
      }
    }
  },
  mounted () {
    // this.getTagAttributeList()
    const { id } = this.$route.query
    this.$setBreadcrumb('编辑属性')
    if (id !== undefined) {
      this.$refs.formPanel.getData({ ruleRealId: id })
    }
  },
  methods: {
    previous () {
      this.$router.go(-1)
    },
    update (data) {
      let basisAttrlist = []
      let attrList = []
      data.forEach((item) => {
        if (item.attributeType == 2) { // eslint-disable-line
          attrList.push(item)
        } else {
          basisAttrlist.push(item)
        }
      })
      this.attrList = attrList
      this.basisAttrlist = basisAttrlist
    },
    getTagAttributeList () {
      this.$axios.get(`${getTagAttributeListUrl}${this.formId}`).then(res => {
        if (res.status == 100) { // eslint-disable-line
          let { data } = res
          let basisAttrlist = []
          let attrList = []
          data.forEach((item) => {
            if (item.attributeType == 2) { // eslint-disable-line
              attrList.push(item)
            } else {
              basisAttrlist.push(item)
            }
          })
          this.attrList = attrList
          this.basisAttrlist = basisAttrlist
        }
      })
    },
    addAttr () {
      const attrObj = {
        id: 0,
        attributeLength: null,
        attributeName: null,
        attributeType: 2,
        inputType: 1,
        required: 1
      }
      this.attrList.push(attrObj)
    },
    removeAttr (index) {
      this.attrList.splice(index, 1)
    },
    saveTagAttr () {
      this.attrList.forEach(item => {
        if (item.inputType === 4) {
          item.attributeLength = 40
        }
      })
      console.log(this.attrList)
      let data = {
        tagId: this.$route.query.id,
        listAttribute: this.attrList
      }
      let submitConfig = {
        url: updategTagAttributeListUrl,
        method: 'post',
        headers: {
          contentType: 'application/json'
        },
        data: data
      }
      this.$axios(submitConfig).then(res => {
        if (res.status == 100) { // eslint-disable-line
          this.previous()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .orgLabel-container {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
  .topDesc {
    padding:10px 15px;
    background-color:#eee;
    margin: 0 20px 20px;
  }
  .group-cell{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    line-height: 33px;
    .c-left {
      display: inline-block;
      min-width: 112px;
      padding-right: 12px;
      text-align: right;
      color: #333333;
      font-weight: 700;
      span {
        color: #F56C6C;
        margin-right: 4px;
      }
    }
    .c-right {

    }
  }
  .control-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    .v-control {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 200px;
      margin-bottom: 10px;
      margin-right: 20px;
    }
    .drop {
      min-width: 60px;
      height: 40px;
    }
  }
  .save {
    width: 140px;
    margin-left: 138px;
    margin-top: 50px;
  }
</style>
